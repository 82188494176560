.chat {
    --bg: #fff;
    --bg-secundary: #e1e1e8;
    --bg-highlight: #057ef7;
    --color: #2a2b2e;
    // max-width: 960px;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    color: var(--color);
    background: var(--bg);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 8px -4px #000 20;
    transition: all ease 0.5s;
    .conversation {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: all ease 0.5s;
        .messages {
            height: calc(100vh - 320px);
            display: flex;
            flex-direction: column;
            gap: 0.25em;
            padding: 1em;
            // overflow: auto;
            overflow-x: hidden;
            overflow-y: scroll;
            scroll-behavior: revert;
            justify-content: flex-end;
            .time {
                font-size: 0.75em;
                margin: 0 auto;
                padding: 0.5em;
                border-radius: 6px;
                min-width: 78px;
            }
            .msg-daytime-bar {
                width: 100%;
                text-align: center;
                border-bottom: 1px solid #b0b0b0;
                line-height: 0.001em;
                margin: 10px 0 20px;
                span {
                    font-size: 12px;
                    font-weight: 600;
                    background: #ffffff;
                    letter-spacing: 0.02em;
                    padding: 0 10px;
                    color: #b0b0b0;
                }
            }
            .msg-width-scroll {
                width: 99.7%;
            }

            // .msg-time-left-bar,
            // .msg-time-right-bar{
            //   border: 1px solid #B0B0B0;
            //   width: 40vw;
            // }
            .msg-text {
                width: fit-content;
                font-size: 0.85em;
                animation: appear-msg ease 0.25s forwards;
                .text {
                    display: block;
                    padding: 0.75em;
                    border-radius: 0.75em 0.75em 0.75em 0;
                    background: var(--bg-secundary);
                    box-shadow: 0 2px 0 #000020;
                }
                .chat-message {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    letter-spacing: 0.03em;
                }
                .message-img {
                    cursor: pointer;
                }
                .message-time {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.20000000298023224px;
                    color: #bababa;
                }
                .msg-time-owner {
                    display: block;
                    text-align: right;
                }
                .message-box {
                    // &:after{
                    //   content: "";
                    //   position: absolute;
                    //   width: 0;
                    //   height: 0;
                    //   left: auto;
                    //   right: -14px;
                    //   top: 0;
                    //   bottom: auto;
                    //   border: 15px solid;
                    //   border-color: #484d8c transparent transparent transparent;
                    // }
                    // &.response{
                    //   &:after{
                    //   left: -14px;
                    //     right: auto;
                    //     border-color: #F6F6F6 transparent transparent transparent;
                    //   }
                    // }
                }
            }
            .recipient-msg-con {
                padding: 10px;
                background: #f2eeff;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 5px;
                span {
                    color: #000;
                }
            }
            .msg-text.owner {
                margin-left: auto;
                .text {
                    color: #fff;
                    background: var(--bg-highlight);
                    border-radius: 0.75em 0.75em 0 0.75em;
                }
                .owner-message {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 0px;
                    background: #8566e7;
                    padding: 10px;
                    span {
                        color: #fff;
                        max-width: 400px;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                        word-break: break-word;

                        .see-more {
                            cursor: pointer;
                            background-color: #8566e7;
                            color: #000;
                            text-decoration: underline;
                            border: none;
                        }
                    }
                }
            }
        }
        .field {
            width: 100%;
            // height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5em;
            padding: 0.25em 0.5em;
            border-top: 1px solid var(--bg-secundary);
            transition: all ease 0.5s;
            .input-message {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                padding: 0.5em 1em;
                border-radius: 50px;
                color: var(--color);
                background: var(--bg-secundary);
                transition: all ease 0.5s;
                &:placeholder-shown {
                    ~ {
                        .text {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    height: calc(100vh - 100px);
    position: relative;
    max-width: 356px;
}
.chat-main-con {
    .main-header-search-box {
        width: 100%;
        .app-search {
            input {
                &::placeholder {
                    font-family: "Urbanist", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0.20000000298023224px;
                    color: #bababa;
                }
            }
        }
    }
    .fancy-scroll {
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            background-color: #e6e7e8;
            width: 3px;
        }
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #e6e7e8;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #704de7;
            width: 3px;
            height: 12px;
            border-radius: 10px;
        }
    }
}

.message-field {
    position: relative;
    fieldset {
        background-color: #f6f6f6;
    }
    textarea {
        resize: none;
        overflow: hidden;
        /* line-height: 18px; */
        padding-right: 45px;
        background: #f6f6f6;
        width: 100%;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.20000000298023224px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 0.75rem;
        border: 1px solid transparent;
    }
    .send-msg-btn {
        min-width: 40px;
        height: 45px;
        border: 1px solid transparent;
        background: #8566e7;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #ffffff;
        border-radius: 4px;
        img {
            width: 18px;
            height: 18px;
        }
    }
    .message-field-action {
        position: absolute;
        top: 0;
        z-index: 9;
        right: 0;
        .sending-loading {
            width: 40px;
            height: 40px;
        }
    }
}

.recipient-details-header {
    min-height: 38px;
}
.alert-message {
    background-color: #f5a04a;
    color: #ffffff !important;
    width: 75%;
    margin: 0 auto;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

.conversation-message-con {
    display: block !important;
}

.recipients-list .recipients-list-item {
    position: relative;
    min-width: 356px;
}
.starred-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    z-index: 9;
}
// .recipients-list .recipients {
//   padding-right: 20px;
// }
.recipients-list .recipients .name {
    line-height: 18px;
}
.recipients-list .recipients .status {
    line-height: 18px;
}

// .recipients-list .recipients .status-color{
//   width: 14px;
//   height: 14px;
//   background-color: #FFB163;
//   border-radius: 2px;
// }
// .recipients-list .recipients .status-color.res-yes{
//   background-color: #4BC0C0;
// }
// .recipients-list .recipients .status-color.res-no{
//   background-color: #36A2EB;
// }
// .recipients-list .recipients .status-color.res-failed{
//   background-color: #FF6384;
// }

.recipients-list .recipients:hover,
.recipients-list .recipients.recipient-active {
    background-color: #f7f4ff;
}

.recipients-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    color: #222222;
}
.recipients-question {
    font-size: 14px;
    // font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    color: #222222;
    max-width: 160px;
}
.last-msg-width {
    max-width: 120px;
}

.title-width {
    max-width: 200px;
}
.bold {
    color: #222222;
    font-weight: 700;
}
.conversation-height {
    height: calc(100vh - 110px);
}

.recipients-panel-height {
    height: calc(100vh - 230px);
}

.conversation-chat-container {
    position: relative;
}

.toggle-side-bar {
    position: absolute;
    left: 15px;
}

.filter-dropdown {
    margin: 0;
    padding: 6px;
    border: 1px solid #484d8c;
    border-radius: 5px;
}
.dropdown-item {
    padding: 0;
    min-height: 20px !important;
    margin-bottom: 5px;
}

.dropdown-item-title {
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    padding: 0 8px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    cursor: pointer;
}

.dropdown-item-title.active,
.dropdown-item-title:hover {
    background-color: #eeefff;
}

.count {
    background-color: #c83535;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: #ffffff;
}
.recipient-active {
    .recipients-row {
        .recipients-question {
            color: #6d6d6d;
        }
    }
}
.recipients-list-time {
    span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.20000000298023224px;
        text-align: right;
        color: #bababa;
    }
}
.chat-panel-arrow-icon {
    display: none;
}
.right-chat-panel-con {
    .recipients {
        .top-header-left-img {
            max-width: 60px;
            height: 60px;
        }
        .recipients-name {
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
        }
        .recipients-question {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: #3e3e40;
        }
    }
}

.loaderClasses {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
}
.load-more:hover {
    font-size: 15px;
    font-weight: 600;
    background-color: transparent !important;
    color: #704de7 !important;
}

@media only screen and (max-width: 991px) {
    .recipients-conversation-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #00000073;
        z-index: 9999;
        padding: 0 !important;
        opacity: 0;
        visibility: hidden;
    }
    .recipients-conversation-container.active {
        opacity: 1;
        visibility: visible;
    }
    .recipients-conversation-box {
        padding: 0;
        max-width: 350px;
        height: 100vh;
        width: 80%;
    }
    .recipients-panel-height {
        height: 75vh;
    }
    .chat-panel-arrow-icon {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .alert-message {
        width: calc(100% - 60px);
        margin: 0 0 10px auto;
        font-size: 12px;
    }
    .right-chat-panel-con {
        .recipients {
            .top-header-left-img {
                max-width: 50px;
                height: 50px;
            }
            .recipients-name {
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
            }
            .recipients-question {
                font-size: 14px;
                font-weight: 500;
                line-height: 15px;
                color: #3e3e40;
            }
        }
    }
}
