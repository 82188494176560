.countries-con{
  .countries-table-con{
    .table{
      min-width: 636px;
    }
    .countries-id-col{
      width: 150px;
    }
    .countries-region-col{
      width: 300px;
    }
    .countries-status-col{
      width: 200px;
    }
  }
}