.case-management-con {
    .cm-case-id {
        max-width: 250px;
    }
    // .cm-cust-name {
    //   max-width: 55px;
    // }
    // .cm-subadmin-name{
    //   max-width: 55px;
    // }
    // .cm-region {
    //   max-width: 55px;
    // }
    // .cm-process {
    //   max-width: 55px;
    // }
    // .cm-status {
    //   max-width: 55px;
    // }
    // .cm-response-status {
    //   max-width: 55px;
    // }
    .cm-action {
        max-width: 55px;
    }
}
.document-view-modal-con {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
.pdf-view-modal-con {
    max-width: 900px;
    width: 900px;
}
.pdf-view-modal-body {
    overflow: auto;
    height: 80vh !important;
    max-height: 80vh !important;
    padding: 1rem !important;
}

.main-header-search-box {
    // width: 341px;
    // height: 40px;
    border-radius: 4px;
    padding: 0px;
    .form-control {
        border: 1px solid #c9b9ff;
        height: 40px;
    }
    .main-header-search-icon {
        color: #704de7;
    }
}
.status-header-search-admin__container {
    min-width: 70%;
}
.status-header-search-admin__indicator {
    color: rgba(112, 77, 231, 1) !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.status-header-search-admin__value-container {
    min-width: 150px;
}
.status-header-search-admin__indicator {
    // color: black !important;
}
.status-header-search-admin__placeholder {
    // color: black !important;
    // font-weight: 500;
}
.status-header-search-admin__single-value {
    // font-weight: 500;
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.20000000298023224px;
    color: #000000;
}
.status-header-search-admin__indicators {
    min-width: inherit;
}
.status-header-search-admin__indicator-separator {
    display: none;
}
.sorting-con {
    font-family: "Urbanist", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #000000;
    .sorting-dropdown {
        font-family: "Urbanist", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: #000000;
        border: 1px solid #c9b9ff;
        background-image: url("../../assets/images/aiAPaiT/downarrow-icon.svg");
        background-size: 12px 12px;
    }
}
.listing-header-con {
    // flex-direction: column;
    // align-items: stretch !important;
    justify-content: flex-end;
    flex-wrap: wrap;
    .main-header-con-modified,
    .main-header-search-box-modified,
    .main-header-type-filter-modified {
        width: 100%;
    }
    .main-header-con-modified,
    .main-header-type-filter-modified {
        margin-top: 1rem;
        justify-content: unset;
    }
    .main-header-search-box-modified {
        div {
            width: inherit;
        }
    }
    .search-filter-con {
        margin-bottom: 0.5rem;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch !important;
        .main-header-search-box {
            width: 100%;
            margin-bottom: 0.5rem;
            margin-bottom: 1rem;
        }
        .main-header-filter-box {
            align-items: center;
            width: 100%;
            label {
                display: none;
                min-width: 80px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.20000000298023224px;
                text-align: left;
                font-family: "Urbanist", sans-serif;
                color: #000000;
            }
            .header-filter {
                width: 100%;
                .status-header-search-admin__placeholder {
                    font-family: "Urbanist", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0.20000000298023224px;
                    text-align: left;
                    color: #bababa;
                }
            }
        }
    }
    .cm-searchfilter {
        margin-bottom: 0rem;
    }
    .btn-con {
        justify-content: flex-end;
        button {
            width: 100%;
        }
    }
    .header-btn {
        padding: 0.52rem 0.75rem;
    }
}
.view-file-buttons{
    font-family: "Urbanist", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.6px;
    text-align: left;
    color: #704DE7;
    border: 1px solid #704DE7;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;

    &:hover{
        color: #fff;
        background-color: #704DE7;
    }
}
@media (min-width: 650px) {
    .listing-header-con {
        // flex-direction: column;
        // align-items: stretch !important;
        .main-header-search-box-modified,
        .main-header-con-modified,
        .main-header-type-filter-modified {
            width: 50%;
        }
        .main-header-type-filter-modified {
            margin-top: 0;
        }
        .main-header-search-box-modified {
            div {
                width: 341px;
            }
        }
        .main-header-con-modified {
            margin-top: 1rem;
        }
        .main-header-con-modified,
        .main-header-type-filter-modified {
            justify-content: flex-end;
        }
        .search-filter-con {
            margin-bottom: 0.5rem;
            margin-bottom: 0rem;
            justify-content: space-between;
            flex-direction: row;
            align-items: center !important;
            .main-header-search-box {
                width: 330px;
                margin-bottom: 0;
            }
            .main-header-filter-box {
                justify-content: end;
                width: auto;
                label {
                    display: block;
                    min-width: 80px;
                }
                .label-filterBy {
                    min-width: 70px;
                }
            }
        }
        .btn-con {
            justify-content: flex-end;
            button {
                width: 213px;
            }
        }
    }
}
@media (min-width: 850px) {
    .listing-header-con {
        // flex-direction: column;
        .search-filter-con {
            margin-bottom: 0;
            // .btn-con{
            //   justify-content: ;
            // }
        }
        .btn-con {
            justify-content: flex-end;
        }
    }
}
@media (min-width: 992px) {
    .listing-header-con {
        // flex-direction: column;
        // align-items: stretch !important;
        .search-filter-con {
            margin-bottom: 0.5rem;
            margin-bottom: 0rem;
            justify-content: space-between;
        }
        .btn-con {
            justify-content: flex-end;
        }
    }
}
@media (min-width: 1100px) {
    .listing-header-con {
        // flex-direction: column;
        .search-filter-con {
            margin-bottom: 0;
            // .btn-con{
            //   justify-content: ;
            // }
        }
        .btn-con {
            justify-content: flex-end;
        }
    }
}
.fitlers-con-case-mang {
    flex-direction: column;
}
@media (min-width: 1250px) {
    .fitlers-con-case-mang {
        flex-direction: row;
    }
    // .listing-header-con{
    //     align-items: stretch !important;
    // }
    .listing-header-con {
        .main-header-search-box-modified {
            width: 341px;
        }
        .main-header-con-modified,
        .main-header-type-filter-modified {
            width: inherit;
            justify-content: unset;
        }
        .main-header-con-modified {
            margin-top: 0;
        }
        .main-header-search-box-modified {
            div {
                width: inherit;
            }
        }
        // flex-direction: row;
        justify-content: flex-start;
        // .search-filter-con {
        //     margin-bottom: 0;
        //     // .btn-con{
        //     //   justify-content: ;
        //     // }
        // }
        // .btn-con {
        //     justify-content: flex-end;
        // }
    }
}
.data-not-found-message {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
}
.case-detail-container {
    padding: 1rem;
    .case-detail-main-container {
        margin-bottom: 1rem;
        .case-detail-form-fields-container {
            padding: 1rem;
            font-family: "Urbanist", sans-serif;
            text-align: left;
            font-weight: 600;
            p {
                font-size: 14px;
                color: #704de7;
            }
            div {
                color: #222222;
                font-size: 18px;
            }
        }
    }
    .case-detail-accordian-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;
        .case-detail-accordian-header {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: #000;
                font-weight: 600;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                background-color: #704de7;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                color: #fff;
                font-size: 14px;
            }
        }
        .case-detail-accordian-header-status {
            font-family: "Urbanist", sans-serif;
            font-size: 18px;
            p {
                color: #222222;
                font-weight: 500;
            }
            span {
                color: #08b256;
                font-weight: 600;
            }
            .open-status-color{
                color: #f69e1a;
            }
        }
    }

    .accordion-item {
        border: none;
    }
    .accordion-button {
        padding: 0.5rem 1rem;
        background-color: #f2eeff;
        &::after {
            background-image: url("../../assets/images/aiAPaiT/case-mangement/accordion-close.svg");
        }
    }
    .accordion-button:not(.collapsed) {
        box-shadow: none;
        &::after {
            margin-bottom: 5px;
        }
    }
    .accordion-body {
        padding: 0;
    }
    .case-detail-accordian-body-con {
        .card {
            margin-bottom: 0px;
            hr {
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
            }
        }
        .case-details-accordian-date-field {
            label {
                font-size: 15px;
                color: #704de7;
                font-weight: 600;
            }
            .bv-text-input {
                box-shadow: none;
            }
            .input-group {
                box-shadow: 0px 3px 12px #706e6e29;
                width: 300px;
                border-radius: 5px;
            }
            input {
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.03em;
                color: #000000;
            }
            input[type="date"]::-webkit-calendar-picker-indicator {
                // color: rgba(0, 0, 0, 0);
                // opacity: 1;
                background-image: url("./../../assets/images/aiAPaiT/case-mangement/date-icon.svg");
                background-repeat: no-repeat;
                background-position: 100% center;
                background-size: 16px;
                cursor: pointer;
            }
        }
        .case-detail-accordian-body {
            margin: 5px 5px 5px 10px;
            padding: 8px;
            font-family: "Urbanist", sans-serif;
            text-align: left;
            div {
                font-size: 15px;
                color: #704de7;
                font-weight: 600;
            }
            p {
                color: #222222;
                font-size: 18px;
                font-weight: 600;
            }
            .case-detail-accordian-body-value {
                font-weight: 700;
            }
            .case-detail-accordian-body-img {
                height: 70px;
                width: 70px;
                background-color: #f2eeff;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 25px;
                    height: 27px;
                }
            }
            .thumbnail-img-con {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .case-detail-accordian-body-trademark-body {
            p {
                font-family: "Urbanist", sans-serif;
                font-weight: 600;
                font-size: 15px;
            }
            .trademark-body-button-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 200px;
                height: 55px;
                .trademark-body-button {
                    color: #704de7;
                    font-size: 15px;
                    padding: 0.2rem 0.8rem;
                    &:hover {
                        background-color: #704de7;
                        color: #fff;
                    }
                }
            }
        }
        .header {
            margin-top: 13px;
            margin-left: 16px;
            color: #704de7;
            font-weight: 700;
            font-size: 15px;
        }
        .case-detail-trademark-con {
            display: flex;
            align-items: flex-end;
        }
        .accordian-body-footer-btn {
            margin-left: 1.1rem;
            margin-bottom: 0.6rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 320px;
            height: 50px;
            .trademark-body-footer-accept-button {
                width: 60%;
                color: #fff;
                background: #704de7;
                font-size: 15px;
                padding: 0.5rem 0.7rem;
                &:hover {
                    background-color: transparent;
                    color: #704de7 !important;
                    border: 1px solid #704de7;
                }
            }
            .trademark-body-footer-reject-button {
                width: 40%;
                color: #e64a4a;
                font-size: 15px;
                padding: 0.5rem 0.7rem;
                border-color: #e64a4a;
                &:hover {
                    background-color: #e64a4a;
                    color: #fff;
                }
            }
        }
    }
    .case-detail-accordian-signature-con {
        margin-top: 20px;
        .case-detail-accordian-body {
            div {
                padding: 10px 0;
                font-family: "Urbanist", sans-serif;
                font-size: 16px;
                font-weight: 600;
                color: #704de7;
            }
            .case-detail-accordian-body-img {
                border-radius: 4px;
                height: 70px;
                width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                div {
                    font-family: "Urbanist", sans-serif;
                    padding-bottom: 7px;
                    font-size: 14px;
                    color: #704de7;
                }
            }
            .size-modifier {
                height: 100px;
                width: 100px;
            }
        }

        .case-detail-accordian-signature-body {
            margin-top: 20px;
            margin-left: 20px;
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 600;
            color: #704de7;
        }
        .e-signature-footer-btn {
            margin: 10px 0 20px 20px;
            display: flex;
            justify-content: space-between;
            width: 650px;
            .accordian-body-footer-text {
                display: flex;
                align-items: center;
                font-family: "Urbanist", sans-serif;
                font-size: 22px;
                font-weight: 600;
                color: #000000;
            }
        }
    }
    .accordion-disable-class {
        // .accordion-item {
        //     .accordion-header {
        //         .accordion-button {
        //             pointer-events: none;
        //             &::after {
        //                 background-image: none;
        //             }
        //         }
        //     }
        // }

        .case-detail-accordian-container {
            .case-detail-accordian-header-status {
                span {
                    color: red;
                }
            }
        }
    }
}

.rejection-reason{
    font-size: 1rem;
}
.rejection-typo-heading{
    font-weight: bold;
}