.goods-services-con{
  .goods-services-table-con{
    .table{
      min-width: 920px;
    }
    .goods-services-no-col{
      width: 150px;
    }
    .goods-services-name-col{
      width: 350px;
    }
    .goods-services-status-col{
      width: 250px;
    }
  }
}