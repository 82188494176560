.table-con {
    .bv-data-table {
        font-family: "Urbanist", sans-serif;
        margin-bottom: 0px;
        thead {
            font-weight: 700;
            font-size: 1rem;
            line-height: 17px;
            letter-spacing: 0.6px;
            th {
                color: #704de7;
                background: #f2eeff;
                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                // font-weight: normal;
            }
        }
        tbody {
            tr {
                background-color: white;
                border: 0;
                transition: all 0.3s;
                td {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 22px;
                    color: #222222;
                    border: 0;
                    background-color: white;
                }
                // &:hover {
                //     box-shadow: -7px 0px 30px #00000029;
                // }
            }
        }
        .bold-font {
            font-weight: 600;
        }
        .width-wrap-text {
            white-space: normal;
        }
        .pointer {
            img {
                width: 21px;
                height: 15px;
            }
        }
        .status-rest {
            border-radius: 37px;
            text-align: center;
            width: fit-content;
            p {
                padding: 0.15rem 1rem;
                margin-bottom: 0px;
            }
        }
        .green-bg-text {
            background: #c7f4dc;
            color: #08b256;
        }
        .red-bg-text {
            background: #ffdfdf;
            color: #e64a4a;
        }
        .blue-bg-text {
            background: #d6eef9;
            color: #0d98d8;
        }
        .yellow-bg-text {
            background: #fbead0;
            color: #f69e1a;
        }

        /* toggle switch */
        .switch-dt {
            position: relative;
            display: inline-block;
            width: 37px;
            height: 18px;
        }
        .switch-pointer-events {
            pointer-events: none;
        }
        .switch-dt input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider-dt {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #b6b6b6;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        .slider-dt:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 2px;
            bottom: 1.5px;
            background-color: white;
            transition: 0.4s;
        }
        input:checked + .slider-dt {
            background-color: #704de7;
        }

        input:focus + .slider-dt {
            box-shadow: 0 0 1px #704de7;
        }
        input:checked + .slider-dt:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
        }

        /* Rounded sliders */
        .slider-dt.round-dt {
            border-radius: 34px;
        }

        .slider-dt.round-dt:before {
            border-radius: 50%;
        }
        .toggle-btn-typo-dt {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.6px;
            color: #b6b6b6;
        }
        .color-active-dt {
            color: #704de7;
        }
    }
}

/* width */
.table-con::-webkit-scrollbar {
    height: 8px;
}

/* Track */
.table-con::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey;
    border-radius: 10px;
}

/* Handle */
.table-con::-webkit-scrollbar-thumb {
    background: #c9b9ff;
    border-radius: 10px;
}

/* Handle on hover */
.table-con::-webkit-scrollbar-thumb:hover {
    background: #c9b9ff;
}

.data-table-head {
    display: flex;
    align-items: center;
    flex-direction: row;

    &.sortable {
        user-select: none;
        cursor: pointer;
    }

    & i {
        margin-left: 1px;
        font-size: 15px;

        &.bx-sort {
            opacity: 0.5;
        }
    }

    & .filter-value {
        font-size: 10px;
        margin-left: 3px;
        font-style: italic;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table-min-height {
    min-height: 350px;
}

.table-loader-container {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-filter-menu {
    font-size: 20px;
    cursor: pointer;
}

.column-filter-item {
    padding-top: 6px;
    padding-bottom: 6px;
    label,
    input {
        cursor: pointer;
    }
}

.column-filter-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}
